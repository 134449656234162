import React, { Component } from "react";
import {Link} from "react-router-dom";

//Load Image
import package1Img from "../../../assets/images/package/p-1.png"
import package2Img from "../../../assets/images/package/p-2.png"
import package3Img from "../../../assets/images/package/p-3.png"
import package4Img from "../../../assets/images/package/p-4.png"
import package5Img from "../../../assets/images/package/p-5.png"
import package6Img from "../../../assets/images/package/p-6.png"
import package7Img from "../../../assets/images/package/p-7.png"

import "../../../i18n";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

// Banners de packages
import andes_a_la_selva_1 from "../../../assets/images/perubike_images/de los andes a la selva/banner_1.JPG"
import andes_a_la_costa_1 from "../../../assets/images/perubike_images/de_los_andes_a_la_costa/banner_1.JPG"
import central_andes_1_1 from "../../../assets/images/perubike_images/centro_andes_1.JPG"
import central_andes_2_1 from "../../../assets/images/perubike_images/central_andes_2/banner_1.JPG";



function withHooks(WrappedComponent) {
    return function(props) {
      const {t} = useTranslation();
      return (
        < WrappedComponent t={t} {...props} />
      );
    };
  }

class Packages extends Component {
  render() {
    const {t} = this.props;
    return (
        <div>
            {/* ===============  breadcrumb area start =============== */}
            <div className="breadcrumb-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="breadcrumb-wrap">
                                <h2>{t("header_9")}</h2>
                                <ul className="breadcrumb-links">
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ===============  breadcrumb area end =============== */}

            {/* ===============  Package  area start =============== */}
            <div className="package-area pt-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="section-head pb-45">
                                <h5>{t("packages_0")}</h5>
                                <h2>{t("packages_1")}</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp animated" data-wow-duration="1500ms" data-wow-delay="0ms">
                            <div className="package-card">
                                <div className="package-thumb">
                                    <Link to={`${process.env.PUBLIC_URL}/centro_historico`}>
                                        <img src={package1Img} alt="" className="img-fluid" />
                                    </Link>
                                </div>
                                <div className="package-details">
                                    <div className="package-info">
                                        <h5><span>$95</span>/ {t("per_person")}</h5>
                                    </div>
                                    <h3>
                                        <i className="flaticon-arrival" />
                                        <Link to={`${process.env.PUBLIC_URL}/centro_historico`}>{t("header_11")}</Link>
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp animated" data-wow-duration="1500ms" data-wow-delay="300ms">
                            <div className="package-card">
                                <div className="package-thumb">
                                    <Link to={`${process.env.PUBLIC_URL}/lima_bahia`}>
                                        <img src={package2Img} alt="" className="img-fluid" />
                                    </Link>
                                </div>
                                <div className="package-details">
                                    <div className="package-info">
                                        <h5><span>$95</span>/ {t("per_person")}</h5>
                                        {/* <h5><i className="flaticon-calendar" />5 Days/6 night</h5> */}
                                    </div>
                                    <h3>
                                        <i className="flaticon-arrival" />
                                        <Link to={`${process.env.PUBLIC_URL}/lima_bahia`}>{t("header_12")}</Link>
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp animated" data-wow-duration="1500ms" data-wow-delay="600ms">
                            <div className="package-card">
                                <div className="package-thumb">
                                    <Link to={`${process.env.PUBLIC_URL}/lima_bohemian`}>
                                        <img src={package3Img} alt="" className="img-fluid" />
                                    </Link>
                                </div>
                                <div className="package-details">
                                    <div className="package-info">
                                        <h5><span>$95</span>/ {t("per_person")}</h5>
                                    </div>
                                    <h3>
                                        <i className="flaticon-arrival" />
                                        <Link to={`${process.env.PUBLIC_URL}/lima_bohemian`}>{t("header_13")}</Link>
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp animated" data-wow-duration="1500ms" data-wow-delay="0ms">
                            <div className="package-card">
                                <div className="package-thumb">
                                    <Link to={`${process.env.PUBLIC_URL}/pachacamac`}>
                                        <img src={package4Img} alt="" className="img-fluid" />
                                    </Link>
                                </div>
                                <div className="package-details">
                                    <div className="package-info">
                                        <h5><span>$187</span>/ {t("per_person")}</h5>
                                    </div>
                                    <h3>
                                        <i className="flaticon-arrival" />
                                        <Link to={`${process.env.PUBLIC_URL}/pachacamac`}>Pachacamac</Link>
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp animated" data-wow-duration="1500ms" data-wow-delay="0ms">
                            <div className="package-card">
                                <div className="package-thumb">
                                    <Link to={`${process.env.PUBLIC_URL}/central_andes`}>
                                        <img src={central_andes_1_1} alt="" className="img-fluid" />
                                    </Link>
                                </div>
                                <div className="package-details">
                                    <div className="package-info">
                                        <h5><span>$575</span>/ {t("per_person")}</h5>
                                    </div>
                                    <h3>
                                        <i className="flaticon-arrival" />
                                        <Link to={`${process.env.PUBLIC_URL}/central_andes`}>{t("central_andes_1")}</Link>
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp animated" data-wow-duration="1500ms" data-wow-delay="0ms">
                            <div className="package-card">
                                <div className="package-thumb">
                                    <Link to={`${process.env.PUBLIC_URL}/central_andes_2`}>
                                        <img src={central_andes_2_1} alt="" className="img-fluid" />
                                    </Link>
                                </div>
                                <div className="package-details">
                                    <div className="package-info">
                                        <h5><span>$575</span>/ {t("per_person")}</h5>
                                    </div>
                                    <h3>
                                        <i className="flaticon-arrival" />
                                        <Link to={`${process.env.PUBLIC_URL}/central_andes_2`}>{t("central_andes_2_1")}</Link>
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp animated" data-wow-duration="1500ms" data-wow-delay="0ms">
                            <div className="package-card">
                                <div className="package-thumb">
                                    <Link to={`${process.env.PUBLIC_URL}/andes_a_la_selva`}>
                                        <img src={andes_a_la_selva_1} alt="" className="img-fluid" />
                                    </Link>
                                </div>
                                <div className="package-details">
                                    <div className="package-info">
                                        <h5><span>$575</span>/ {t("per_person")}</h5>
                                    </div>
                                    <h3>
                                        <i className="flaticon-arrival" />
                                        <Link to={`${process.env.PUBLIC_URL}/andes_a_la_selva`}>{t("andes_a_la_selva_1")}</Link>
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp animated" data-wow-duration="1500ms" data-wow-delay="0ms">
                            <div className="package-card">
                                <div className="package-thumb">
                                    <Link to={`${process.env.PUBLIC_URL}/andes_a_la_costa`}>
                                        <img src={andes_a_la_costa_1} alt="" className="img-fluid" />
                                    </Link>
                                </div>
                                <div className="package-details">
                                    <div className="package-info">
                                        <h5><span>$575</span>/ {t("per_person")}</h5>
                                    </div>
                                    <h3>
                                        <i className="flaticon-arrival" />
                                        <Link to={`${process.env.PUBLIC_URL}/andes_a_la_selva`}>{t("andes_a_la_costa_1")}</Link>
                                    </h3>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default withHooks(Packages);
