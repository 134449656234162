import React, { Component } from "react";
import {Link} from "react-router-dom";

import pd_thumb from "../../../assets/images/perubike_images/IMG_0815.d8467497.JPG"
import pd_thumb_2 from "../../../assets/images/perubike_images/IMG_0417.93d0f758.JPG"
import pd_thumb_3 from "../../../assets/images/perubike_images/PERUBIKE-13.9edf7304.jpg"
import pd_thumb_4 from "../../../assets/images/perubike_images/bicicleta_de_man_en_verde.jpg"
import pd_thumb_5 from "../../../assets/images/perubike_images/IMG_1436.81be3613.JPG"
import pd_thumb_6 from "../../../assets/images/perubike_images/IMG_1068.a45e190b.JPG"
import pd_thumb_7 from "../../../assets/images/perubike_images/recorte_1.JPG"
import pd_thumb_8 from "../../../assets/images/perubike_images/recorte_2.JPG"
import pd_thumb_9 from "../../../assets/images/perubike_images/IMG_0822.fa3804e3.JPG"
import pd_thumb_10 from "../../../assets/images/perubike_images/recorte_3.JPG"
import pd_thumb_11 from "../../../assets/images/perubike_images/recorte_4.JPG"
import pd_thumb_12 from "../../../assets/images/perubike_images/recorte_5.JPG"
import pd_thumb_13 from "../../../assets/images/perubike_images/recorte_6.JPG"
import pd_thumb_14 from "../../../assets/images/perubike_images/IMG_0900.75baba0c.JPG"
import pd_thumb_15 from "../../../assets/images/perubike_images/IMG_0885.ba6e5a37.JPG"
import pr_1 from "../../../assets/images/package/pr-1.png"

// Banners de packages
import centro_historico_1  from "../../../assets/images/banners/banner_centro_historico.JPG";
import andes_a_la_selva_1 from "../../../assets/images/perubike_images/de los andes a la selva/banner_1.JPG"
import andes_a_la_costa_1 from "../../../assets/images/perubike_images/de_los_andes_a_la_costa/banner_1.JPG"
import central_andes_1_1 from "../../../assets/images/perubike_images/centro_andes_1.JPG"
import central_andes_2_1 from "../../../assets/images/perubike_images/central_andes_2/banner_1.JPG";


import gallery1Img from "../../../assets/images/gallary/galeria_bohemian/IMG_0097.0351ecf9.jpg"

import pm_sm_1  from "../../../assets/images/banners/banner_lima_bohemian.JPG";
import pm_sm_2  from "../../../assets/images/banners/banner_lima_bahia.jpg";
import pm_sm_3  from "../../../assets/images/banners/banner_pachacamac.JPG";
import pm_sm_4  from "../../../assets/images/banners/banner_centro_historico.JPG";
import pm_sm_5 from "../../../assets/images/perubike_images/centro_andes_1.JPG"

import { SRLWrapper } from "simple-react-lightbox";

import "react-datepicker/dist/react-datepicker.css";


import "../../../i18n";
import { useTranslation } from "react-i18next";
import { t } from "i18next";


function withHooks(WrappedComponent) {
    return function(props) {
      const {t} = useTranslation();
      return (
        < WrappedComponent t={t} {...props} />
      );
    };
  }

class Limabahia extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate:new Date()
        };
    }
    changeDatepickerHandeller=(date)=>{
        this.setState({ startDate: date });
    }

    componentDidMount(){
        this.scrollTop();
    }

    scrollTop()
    {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
  render() {
    const startDate =this.state.startDate;

    const {t} = this.props;
    return (
        <>
            <div className="breadcrumb-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="breadcrumb-wrap">
                                <h2>{t("lima_bahia_1")}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="package-details-wrapper pt-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="package-details">
                                <div className="package-thumb">
                                    <img src={pd_thumb} alt="" />
                                </div>
                                <div className="package-header">
                                    <div className="package-title">
                                        <h3>{t("lima_bahia_2")}</h3>
                                        <h4>{t("lima_bahia_3")}</h4>
                                    </div>
                                </div>
                                <div className="package-tab">
                                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true"><i className="flaticon-info" />
                                            {t("lima_bahia_11")}</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false"> <i className="flaticon-gallery" />
                                            {t("lima_bahia_15")}</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content p-tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="tab-content-1">
                                                        <div className="p-overview">
                                                            <h5>{t("lima_bahia_16")}</h5>
                                                            <p>
                                                                {t("lima_bahia_17")}
                                                            </p>
                                                            <p>
                                                                {t("lima_bahia_18")}
                                                            </p>
                                                            <br/>
                                                            <h5 style={{"margin":'0px'}}>
                                                                {t("lima_bahia_19")}
                                                            </h5>
                                                            <p><b>
                                                                {t("lima_bahia_20")}
                                                            </b></p>
                                                            <p><b> 
                                                            {t("lima_bahia_21")}
                                                            </b></p>
                                                            <p>
                                                            {t("lima_bahia_22")}<b>{t("lima_bahia_23")}</b>.

                                                            </p>
                                                            <br/>
                                                            <div className="package-thumb">
                                                                <img src={pd_thumb_4} alt="" />
                                                            </div>
                                                            <p>
                                                                <b>{t("lima_bahia_25")}</b>{t("lima_bahia_26")}
                                                            </p>
                                                            <br/>
                                                            <h5 style={{"margin":"0px"}}>{t("lima_bahia_27")}</h5>
                                                            <p><b>{t("lima_bahia_28")}</b></p> 
                                                            <p><b>{t("lima_bahia_29")}</b></p>
                                                            <p>
                                                            {t("lima_bahia_30")}
                                                            </p>
                                                            <br/>
                                                            <div className="package-thumb">
                                                                <img src={pd_thumb_3} alt="" />
                                                            </div>
                                                            <p>
                                                                <b>{t("lima_bahia_31")}</b>{t("lima_bahia_32")}
                                                            </p>
                                                            <p>
                                                            {t("lima_bahia_33")}
                                                            </p>
                                                            <p>
                                                                <b>{t("lima_bahia_34")}</b>{t("lima_bahia_35")}
                                                            </p>
                                                            <br/>
                                                            <h5 style={{"margin":"0px"}}>{t("lima_bahia_36")}</h5>
                                                            <p>{t("lima_bahia_37")}</p>
                                                            <p>{t("lima_bahia_38")}</p>
                                                            <p>
                                                            {t("lima_bahia_39")}
                                                            </p>
                                                            <p>
                                                            {t("lima_bahia_40")}
                                                            </p>
                                                            <br/>
                                                            <div className="package-thumb">
                                                                <img src={pd_thumb_2} alt="" />
                                                            </div>
                                                            <p>
                                                                <b>{t("lima_bahia_41")}</b>{t("lima_bahia_42")}
                                                            </p>

                                                            <br/>
                                                            <h5 style={{"margin":"0px"}}>
                                                            {t("lima_bahia_43")}
                                                            </h5>
                                                            <p>
                                                                <b>{t("lima_bahia_44")}</b>{t("lima_bahia_45")}

                                                            </p>
                                                            <p>
                                                                <b>{t("lima_bahia_46")}</b>{t("lima_bahia_47")}
                                                            </p>
                                                        </div>
                                                        <br/>
                                                        <div className="p-details-table">
                                                            <table className="table caption-top">
                                                                <tbody>
                                                                <tr>
                                                                    <td>{t("lima_bahia_57")}</td>
                                                                    <td>{t("lima_bahia_58")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("lima_bahia_59")}</td>
                                                                    <td>{t("lima_bahia_60")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("lima_bahia_61")}</td>
                                                                    <td>{t("lima_bahia_62")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("lima_bahia_63")}</td>
                                                                    <td>{t("lima_bahia_64")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("lima_bahia_65")}</td>
                                                                    <td>{t("lima_bahia_66")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("lima_bahia_67")}</td>
                                                                    <td>
                                                                        <ul className="table-list-allow">
                                                                            <li><i className="bx bx-check" />{t("lima_bahia_68")}</li>
                                                                            <li> <i className="bx bx-check" />{t("lima_bahia_69")}</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("lima_bahia_70")}</td>
                                                                    <td>
                                                                        <ul className="table-list-allow">
                                                                            <li><i className="bx bx-check" />{t("lima_bahia_71")}</li>
                                                                            <li> <i className="bx bx-check" />{t("lima_bahia_72")}</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("lima_bahia_73")}</td>
                                                                    <td>
                                                                        <ul className="table-list-disallow">
                                                                            <li> <i className="bx bx-x" />{t("lima_bahia_74")}</li>
                                                                            <li><i className="bx bx-x" /> {t("lima_bahia_75")}</li>
                                                                            <li><i className="bx bx-x" /> {t("lima_bahia_76")}</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("lima_bahia_77")}</td>
                                                                    <td>
                                                                        <ul className="table-list-allow">
                                                                            <li><i className="bx bx-check" />{t("lima_bahia_78")}</li>
                                                                            <li> <i className="bx bx-check" />{t("lima_bahia_79")}</li>
                                                                            <li> <i className="bx bx-check" />{t("lima_bahia_80")}</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("lima_bahia_81")}</td>
                                                                    <td>
                                                                        <ul className="table-list-allow">
                                                                            <li> <i className="bx bx-check" /> 
                                                                            {t("lima_bahia_82")}</li>
                                                                            <li> <i className="bx bx-check" /> 
                                                                            {t("lima_bahia_83")}</li>
                                                                            <li> <i className="bx bx-check" /> 
                                                                            {t("lima_bahia_84")}</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                            <div className="package-thumb">
                                                                <img src={gallery1Img} alt=""/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                            <div className="tab-contant-3">
                                                <SRLWrapper>
                                                <div className="row">
                                                    <div className="col-lg-8 col-md-8">
                                                        <div className="package-grid-one">
                                                            <div className="single-grid">
                                                                <Link to={gallery1Img} className="g-img-sm-1 main-gallary">
                                                                    <img src={gallery1Img} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={pd_thumb_2} className="g-img-sm-2 main-gallary">
                                                                    <img src={pd_thumb_2} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={pd_thumb_7} className="g-img-md main-gallary">
                                                                    <img src={pd_thumb_7} alt="gallary-img" />
                                                                </Link>
                                                            </div>
                                                            <div className="single-grid mt-24">
                                                                <Link to={pd_thumb} className="g-img-sm-1 main-gallary">
                                                                    <img src={pd_thumb} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={pd_thumb_9} className="g-img-sm-2 main-gallary">
                                                                    <img src={pd_thumb_9} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={pd_thumb_10} className="g-img-md main-gallary">
                                                                    <img src={pd_thumb_10} alt="gallary-img" />
                                                                </Link>
                                                            </div>

                                                            <div className="single-grid mt-24">

                                                                <Link to={pd_thumb_15} className="g-img-sm-1 main-gallary">
                                                                    <img src={pd_thumb_15} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={pd_thumb_14} className="g-img-sm-2 main-gallary">
                                                                    <img src={pd_thumb_14} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={pd_thumb_11} className="g-img-md main-gallary">
                                                                    <img src={pd_thumb_11} alt="gallary-img" />
                                                                </Link>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4">
                                                        <div className="package-grid-two">
                                                            <div className="single-grid-2">
                                                                <Link to={pd_thumb_8}  className="main-gallary">
                                                                    <img src={pd_thumb_8} alt="gallary-img" />
                                                                </Link>
                                                            </div>
                                                            <div className="single-grid-2 mt-24">
                                                                <Link to={pd_thumb_12} className="single-grid-2 main-gallary mt-30">
                                                                    <img src={pd_thumb_12} alt="gallary-img" />
                                                                </Link>
                                                            </div>
                                                            <div className="single-grid-2 mt-24">
                                                                <Link to={pd_thumb_13} className="main-gallary mt-30">
                                                                    <img src={pd_thumb_13} alt="gallary-img" />
                                                                </Link>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                </SRLWrapper>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="package-d-sidebar">
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="p-sidebar-form">
                                            <form>
                                                <h5 className="package-d-head">{t("lima_bahia_85")}</h5>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <input type="submit" value="Book Now" />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-6">
                                        <div className="p-sidebar-cards mt-40">
                                            <h5 className="package-d-head">{t("popular_packages")}</h5>
                                            <ul className="package-cards">
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={pm_sm_3} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/pachacamac`}>Pachacamac</Link>
                                                        </h3>
                                                        <h5><span>$187</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={pm_sm_1} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/lima_bohemian`}>{t("pachacamac_56")}</Link>
                                                        </h3>
                                                        <h5><span>$95</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={pm_sm_4} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/centro_historico`}>{t("lima_bohemian_51")}</Link>
                                                        </h3>
                                                        <h5><span>$95</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={pm_sm_5} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/central_andes`}>{t("central_andes_1")}</Link>
                                                        </h3>
                                                        <h5><span>$575</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={central_andes_2_1} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/central_andes_2`}>{t("central_andes_2_1")}</Link>
                                                        </h3>
                                                        <h5><span>$575</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={andes_a_la_selva_1} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/andes_a_la_selva`}>{t("andes_a_la_selva_1")}</Link>
                                                        </h3>
                                                        <h5><span>$780</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={andes_a_la_costa_1} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/andes_a_la_costa`}>{t("andes_a_la_costa_1")}</Link>
                                                        </h3>
                                                        <h5><span>$780</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
  }
}

export default withHooks(Limabahia);
