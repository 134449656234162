import React, { Component } from "react";
import {Link} from "react-router-dom";

import pm_sm_1  from "../../../assets/images/banners/banner_lima_bohemian.JPG";
import pm_sm_4  from "../../../assets/images/banners/banner_lima_bahia.jpg";
import pm_sm_2  from "../../../assets/images/banners/banner_pachacamac.JPG";

// Banners de packages
import centro_historico_1  from "../../../assets/images/banners/banner_centro_historico.JPG";
import andes_a_la_selva_1 from "../../../assets/images/perubike_images/de los andes a la selva/banner_1.JPG"
import andes_a_la_costa_1 from "../../../assets/images/perubike_images/de_los_andes_a_la_costa/banner_1.JPG"
import central_andes_1_1 from "../../../assets/images/perubike_images/centro_andes_1.JPG"

// hay hasta el numero 21
import central_andes_2_2 from "../../../assets/images/perubike_images/central_andes_2/central_andes_2_1.jpg";
import central_andes_2_3 from "../../../assets/images/perubike_images/central_andes_2/central_andes_2_2.JPG";
import central_andes_2_4 from "../../../assets/images/perubike_images/central_andes_2/central_andes_2_3.JPG";
import central_andes_2_5 from "../../../assets/images/perubike_images/central_andes_2/central_andes_2_4.JPG";
import central_andes_2_6 from "../../../assets/images/perubike_images/central_andes_2/central_andes_2_5.JPG";
import central_andes_2_7 from "../../../assets/images/perubike_images/central_andes_2/central_andes_2_6.JPG";
import central_andes_2_8 from "../../../assets/images/perubike_images/central_andes_2/central_andes_2_7.JPG";
import central_andes_2_9 from "../../../assets/images/perubike_images/central_andes_2/central_andes_2_8.JPG";
import central_andes_2_10 from "../../../assets/images/perubike_images/central_andes_2/central_andes_2_9.JPG";
import central_andes_2_11 from "../../../assets/images/perubike_images/central_andes_2/central_andes_2_10.JPG";
import central_andes_2_12 from "../../../assets/images/perubike_images/central_andes_2/central_andes_2_11.JPG";
import central_andes_2_13 from "../../../assets/images/perubike_images/central_andes_2/central_andes_2_12.JPG";
import central_andes_2_14 from "../../../assets/images/perubike_images/central_andes_2/central_andes_2_13.JPG";
import central_andes_2_15 from "../../../assets/images/perubike_images/central_andes_2/central_andes_2_14.JPG";
import central_andes_2_16 from "../../../assets/images/perubike_images/central_andes_2/central_andes_2_15.JPG";
import central_andes_2_17 from "../../../assets/images/perubike_images/central_andes_2/central_andes_2_16.JPG";
import central_andes_2_18 from "../../../assets/images/perubike_images/central_andes_2/central_andes_2_17.JPG";
import central_andes_2_19 from "../../../assets/images/perubike_images/central_andes_2/central_andes_2_18.JPG";
import central_andes_2_20 from "../../../assets/images/perubike_images/central_andes_2/central_andes_2_19.JPG";
import central_andes_2_21 from "../../../assets/images/perubike_images/central_andes_2/central_andes_2_20.JPG";
import central_andes_2_22 from "../../../assets/images/perubike_images/central_andes_2/central_andes_2_21.JPG";
import central_andes_2_23 from "../../../assets/images/perubike_images/central_andes_2/central_andes_2_22.JPG";


import { SRLWrapper } from "simple-react-lightbox";

import "react-datepicker/dist/react-datepicker.css";

import "../../../i18n";
import { useTranslation } from "react-i18next";


function withHooks(WrappedComponent) {
    return function(props) {
      const {t} = useTranslation();
      return (
        < WrappedComponent t={t} {...props} />
      );
    };
  }

class CentralAndes2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate:new Date(),
        };
    }
    changeDatepickerHandeller=(date)=>{
        this.setState({ startDate: date });
    }

    componentDidMount(){
        this.scrollTop();
    }

    scrollTop()
    {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
  render() {
    const startDate =this.state.startDate;

    const {t} = this.props;

    return (
        <>
            {/* ===============  breadcrumb area start =============== */}
            <div className="breadcrumb-area-centro-andes-2  ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="breadcrumb-wrap">
                                <h2>{t("centro_historico_1")}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ===============  breadcrumb area end =============== */}
            <div className="package-details-wrapper pt-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="package-details">
                                <div className="package-thumb">
                                    <img src={central_andes_2_4} alt="" />
                                </div>
                                <div className="package-header">
                                    <div className="package-title">
                                        <h3>{t("central_andes_2_1")}</h3>
                                        {/* <h4>Sundays Only</h4> */}
                                        <h4>{t("centro_historico_3")}</h4>
                                        {/* <strong><i className="flaticon-arrival" />
                                            Mount Dtna, Spain
                                        </strong> */}
                                    </div>
                                    {/* <div className="pd-review">
                                        <p>Excellent</p>
                                        <ul>
                                            <li><i className="bx bxs-star" /></li>
                                            <li><i className="bx bxs-star" /></li>
                                            <li><i className="bx bxs-star" /></li>
                                            <li><i className="bx bxs-star" /></li>
                                            <li><i className="bx bx-star" /></li>
                                        </ul>
                                        <p>800 Review</p>
                                    </div> */}
                                </div>
                                <div className="package-tab">
                                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true"><i className="flaticon-info" />
                                                {t("information")}</button>
                                        </li>
                                        {/* <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false"><i className="flaticon-clipboard" />
                                                Travel Plan</button>
                                        </li> */}
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false"> <i className="flaticon-gallery" />
                                                {t("our_gallary")}</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content p-tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="tab-content-1">
                                                        <div className="p-overview">
                                                            <h5>{t("centro_historico_3")}</h5>
                                                            <p>{t("central_andes_2")}</p>
                                                        </div>
                                                        <br/>
                                                        <div className="p-overview">
                                                            <h5 style={{"margin":"0px"}}>{t("central_andes_14")}</h5>
                                                            <p>{t("central_andes_15")}</p> 
                                                            <p>{t("central_andes_16")}</p>
                                                            <p>{t("central_andes_17")}</p>
                                                        </div>
                                                        <br/>
                                                        <div className="p-overview">
                                                            <h5 style={{"margin":"0px"}}>{t("central_andes_2_16")}</h5>
                                                            <p>{t("central_andes_2_17")}</p> 
                                                            <p>{t("central_andes_2_18")}</p>
                                                            <p>{t("central_andes_2_19")}</p>
                                                        </div>
                                                        <br/>
                                                        <div className="p-overview">
                                                            <h5 style={{"margin":"0px"}}>{t("central_andes_2_20")}</h5>
                                                            <p>{t("central_andes_2_21")}</p>
                                                        </div>
                                                        <br/>
                                                        <div className="p-overview">
                                                            <h5 style={{"margin":"0px"}}>{t("central_andes_2_22")}</h5>
                                                            <p>{t("central_andes_2_23")}</p>
                                                        </div>
                                                        <br/>
                                                        <div className="package-thumb">
                                                            <img src={central_andes_2_5} alt="" />
                                                        </div>
                                                        <br/>
                                                        {/* <p>{t("centro_historico_9")}</p>
                                                        <p>
                                                            <b>{t("centro_historico_10")}</b> 
                                                            {t("centro_historico_10.1")}
                                                        </p> */}
                                                        <div className="p-details-table">
                                                            <table className="table caption-top">
                                                                <tbody>
                                                                <tr>
                                                                    <td>{t("central_andes_30")}</td>
                                                                    <td>{t("central_andes_32")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("central_andes_33")}</td>
                                                                    <td>{t("central_andes_33_1")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("central_andes_3")}</td>
                                                                    <td>{t("central_andes_4")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("central_andes_5")}</td>
                                                                    <td>{t("central_andes_6")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("central_andes_7")}</td>
                                                                    <td>{t("central_andes_8")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("central_andes_11")}</td>
                                                                    <td>{t("central_andes_12")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("central_andes_13")}</td>
                                                                    <td>{t("central_andes_14")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("central_andes_37")}</td>
                                                                    <td>
                                                                        <ul className="table-list-allow">
                                                                            <li><i className="bx bx-check" />{t("central_andes_38")}</li>
                                                                            <li><i className="bx bx-check" />{t("central_andes_39")}</li>
                                                                            <li><i className="bx bx-check" />{t("central_andes_40")}</li>
                                                                            <li><i className="bx bx-check" />{t("central_andes_41")}</li>
                                                                            <li><i className="bx bx-check" />{t("central_andes_42")}</li>
                                                                            <li><i className="bx bx-check" />{t("central_andes_43")}</li>
                                                                            <li><i className="bx bx-check" />{t("central_andes_44")}</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("central_andes_45")}</td>
                                                                    <td>
                                                                        <ul className="table-list-allow">
                                                                            <li><i className="bx bx-x" /> {t("central_andes_46")}</li>
                                                                            <li> <i className="bx bx-x" /> {t("central_andes_47")}</li>
                                                                            <li><i className="bx bx-x" /> {t("central_andes_48")}</li>
                                                                            <li><i className="bx bx-x" /> {t("central_andes_49")}</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("central_andes_50")}</td>
                                                                    <td>{t("central_andes_51")}</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                            <div className="tab-contant-3">
                                                <SRLWrapper>
                                                <div className="row">
                                                    <div className="col-lg-8 col-md-8">
                                                        <div className="package-grid-one">
                                                            <div className="single-grid">
                                                                <Link to={central_andes_2_10} className="g-img-md-2 main-gallary">
                                                                    <img src={central_andes_2_10} alt="gallary-img" />
                                                                </Link>

                                                                <Link to={central_andes_2_11} className="g-img-sm-2-2 main-gallary">
                                                                    <img src={central_andes_2_11} alt="gallary-img" />
                                                                </Link>

                                                                <Link to={central_andes_2_12} className="g-img-sm-2-2 main-gallary">
                                                                    <img src={central_andes_2_12} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={central_andes_2_6} className="g-img-sm-2-2 main-gallary">
                                                                    <img src={central_andes_2_6} alt="gallary-img" />
                                                                </Link>
                                                            </div>
                                                            <div className="single-grid mt-24">
                                                                <Link to={central_andes_2_13} className="g-img-sm-2-2 main-gallary">
                                                                    <img src={central_andes_2_13} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={central_andes_2_14} className="g-img-md-2 main-gallary">
                                                                    <img src={central_andes_2_14} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={central_andes_2_15} className="g-img-sm-1-2 main-gallary">
                                                                    <img src={central_andes_2_15} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={central_andes_2_7} className="g-img-sm-2-2 main-gallary">
                                                                    <img src={central_andes_2_7} alt="gallary-img" />
                                                                </Link>
                                                            </div>

                                                            <div className="single-grid mt-24">
                                                                <Link to={central_andes_2_16} className="g-img-sm-2-2 main-gallary">
                                                                    <img src={central_andes_2_17} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={central_andes_2_9} className="g-img-md-2 main-gallary">
                                                                    <img src={central_andes_2_9} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={central_andes_2_19} className="g-img-sm-1-2 main-gallary">
                                                                    <img src={central_andes_2_19} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={central_andes_2_8} className="g-img-sm-2-2 main-gallary">
                                                                    <img src={central_andes_2_8} alt="gallary-img" />
                                                                </Link>
                                                            </div>

                                                            <div className="single-grid mt-24">
                                                                <Link to={central_andes_2_5} className="g-img-sm-2-2 main-gallary">
                                                                    <img src={central_andes_2_5} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={central_andes_2_18} className="g-img-md-2 main-gallary">
                                                                    <img src={central_andes_2_18} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={central_andes_2_22} className="g-img-sm-1-2 main-gallary">
                                                                    <img src={central_andes_2_22} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={central_andes_2_4} className="g-img-sm-2-2 main-gallary">
                                                                    <img src={central_andes_2_4} alt="gallary-img" />
                                                                </Link>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4">
                                                        <div className="package-grid-two">
                                                            <div className="single-grid-3">
                                                                <Link to={central_andes_2_20}  className="main-gallary">
                                                                    <img src={central_andes_2_20} alt="gallary-img" />
                                                                </Link>
                                                            </div>
                                                            <div className="single-grid-3 mt-24">
                                                                <Link to={central_andes_2_21}  className="single-grid-2 main-gallary mt-30">
                                                                    <img src={central_andes_2_21} alt="gallary-img" />
                                                                </Link>
                                                            </div>
                                                            <div className="single-grid-3 mt-24">
                                                                <Link to={central_andes_2_19}  className="main-gallary mt-30">
                                                                    <img src={central_andes_2_19} alt="gallary-img" />
                                                                </Link>
                                                            </div>
                                                            <div className="single-grid-3 mt-24">
                                                                <Link to={central_andes_2_3}  className="main-gallary mt-30">
                                                                    <img src={central_andes_2_3} alt="gallary-img" />
                                                                </Link>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                </SRLWrapper>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="package-d-sidebar">
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="p-sidebar-form">
                                            <form>
                                                <h5 className="package-d-head">{t("book_this_package")}</h5>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <input type="submit" name={t("book_now")} value={t("book_now")} />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-6">
                                        <div className="p-sidebar-cards mt-40">
                                            <h5 className="package-d-head">{t("popular_packages")}</h5>
                                            <ul className="package-cards">
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={pm_sm_4} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/lima_bahia`}>{t("lima_bohemian_45")}</Link>
                                                        </h3>
                                                        <h5><span>$95</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={pm_sm_1} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/lima_bohemian`}>{t("pachacamac_56")}</Link>
                                                        </h3>
                                                        <h5><span>$95</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={pm_sm_2} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/pachacamac`}>Pachacamac</Link>
                                                        </h3>
                                                        <h5><span>$187</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={centro_historico_1} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/centro_historico`}>{t("lima_bohemian_51")}</Link>
                                                        </h3>
                                                        <h5><span>$95</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={central_andes_1_1} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/central_andes`}>{t("central_andes_1")}</Link>
                                                        </h3>
                                                        <h5><span>$575</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={andes_a_la_selva_1} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/andes_a_la_selva`}>{t("andes_a_la_selva_1")}</Link>
                                                        </h3>
                                                        <h5><span>$780</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={andes_a_la_costa_1} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/andes_a_la_costa`}>{t("andes_a_la_costa_1")}</Link>
                                                        </h3>
                                                        <h5><span>$780</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
  }
}

// export default CentroHistorico;
export default withHooks(CentralAndes2);
