import React, { Component } from "react";
import {Link} from "react-router-dom";

import pd_thumb_2 from "../../../assets/images/perubike_images/IMG_1346.37674283.JPG"
import pd_thumb_3 from "../../../assets/images/perubike_images/IMG_0669.dfd1349f.JPG"
import pd_thumb_4 from "../../../assets/images/perubike_images/IMG_0260.8d3ceaa0.JPG"
import pd_thumb_5 from "../../../assets/images/perubike_images/recorte_10.JPG"
import pd_thumb_6 from "../../../assets/images/perubike_images/recorte_11.JPG"
import pd_thumb_7 from "../../../assets/images/perubike_images/IMG_1358.63775dea.JPG"
import pd_thumb_8 from "../../../assets/images/perubike_images/IMG_1391.2e4cda11.JPG"
import pd_thumb_9 from "../../../assets/images/perubike_images/IMG_0459.17ab7242.JPG"
import pd_thumb_10 from "../../../assets/images/perubike_images/recorte_12.JPG"
import pd_thumb_11 from "../../../assets/images/perubike_images/IMG_6691.59416c40.JPG"
import pd_thumb_12 from "../../../assets/images/perubike_images/IMG_0279.3081a9d1.JPG"
import pd_thumb_14 from "../../../assets/images/perubike_images/recorte_13.JPG"
import pd_thumb_15 from "../../../assets/images/perubike_images/recorte_14.JPG"

import pm_sm_1  from "../../../assets/images/banners/banner_lima_bohemian.JPG";
import pm_sm_4  from "../../../assets/images/banners/banner_lima_bahia.jpg";
import pm_sm_2  from "../../../assets/images/banners/banner_pachacamac.JPG";
import pm_sm_3  from "../../../assets/images/banners/banner_centro_historico.JPG";
import pm_sm_5 from "../../../assets/images/perubike_images/centro_andes_1.JPG"

// Banners de packages
import centro_historico_1  from "../../../assets/images/banners/banner_centro_historico.JPG";
import andes_a_la_selva_1 from "../../../assets/images/perubike_images/de los andes a la selva/banner_1.JPG"
import andes_a_la_costa_1 from "../../../assets/images/perubike_images/de_los_andes_a_la_costa/banner_1.JPG"
import central_andes_1_1 from "../../../assets/images/perubike_images/centro_andes_1.JPG"
import central_andes_2_1 from "../../../assets/images/perubike_images/central_andes_2/banner_1.JPG";


import { SRLWrapper } from "simple-react-lightbox";

import "react-datepicker/dist/react-datepicker.css";

import "../../../i18n";
import { useTranslation } from "react-i18next";


function withHooks(WrappedComponent) {
    return function(props) {
      const {t} = useTranslation();
      return (
        < WrappedComponent t={t} {...props} />
      );
    };
  }

class CentroHistorico extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate:new Date(),
        };
    }
    changeDatepickerHandeller=(date)=>{
        this.setState({ startDate: date });
    }

    componentDidMount(){
        this.scrollTop();
    }

    scrollTop()
    {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
  render() {
    const startDate =this.state.startDate;

    const {t} = this.props;

    return (
        <>
            {/* ===============  breadcrumb area start =============== */}
            <div className="breadcrumb-area-centro-historico">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="breadcrumb-wrap">
                                <h2>{t("centro_historico_1")}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ===============  breadcrumb area end =============== */}
            <div className="package-details-wrapper pt-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="package-details">
                                <div className="package-thumb">
                                    <img src={pd_thumb_2} alt="" />
                                </div>
                                <div className="package-header">
                                    <div className="package-title">
                                        <h3>{t("centro_historico_2")}</h3>
                                        {/* <h4>Sundays Only</h4> */}
                                        <h4>{t("centro_historico_3")}</h4>
                                        {/* <strong><i className="flaticon-arrival" />
                                            Mount Dtna, Spain
                                        </strong> */}
                                    </div>
                                    {/* <div className="pd-review">
                                        <p>Excellent</p>
                                        <ul>
                                            <li><i className="bx bxs-star" /></li>
                                            <li><i className="bx bxs-star" /></li>
                                            <li><i className="bx bxs-star" /></li>
                                            <li><i className="bx bxs-star" /></li>
                                            <li><i className="bx bx-star" /></li>
                                        </ul>
                                        <p>800 Review</p>
                                    </div> */}
                                </div>
                                <div className="package-tab">
                                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true"><i className="flaticon-info" />
                                                {t("information")}</button>
                                        </li>
                                        {/* <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false"><i className="flaticon-clipboard" />
                                                Travel Plan</button>
                                        </li> */}
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false"> <i className="flaticon-gallery" />
                                                {t("our_gallary")}</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content p-tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="tab-content-1">
                                                        <div className="p-overview">
                                                            <h5>{t("centro_historico_3")}</h5>
                                                            <p>{t("centro_historico_4")}</p>
                                                        </div>
                                                        <br/>
                                                        <div className="p-overview">
                                                            <h5 style={{"margin":"0px"}}>{t("centro_historico_5")}</h5>
                                                            <p><b>{t("centro_historico_6")}</b></p> 
                                                            <p><b>{t("centro_historico_7")}</b></p>
                                                            <p>
                                                                <b>{t("centro_historico_8")}</b>{t("centro_historico_8.1")}
                                                            </p>
                                                        </div>
                                                        <br/>
                                                        <div className="package-thumb">
                                                            <img src={pd_thumb_3} alt="" />
                                                        </div>
                                                        <br/>
                                                        <p>{t("centro_historico_9")}</p>
                                                        <p>
                                                            <b>{t("centro_historico_10")}</b> 
                                                            {t("centro_historico_10.1")}
                                                        </p>
                                                        <div className="p-details-table">
                                                            <table className="table caption-top">
                                                                <tbody>
                                                                <tr>
                                                                    <td>{t("centro_historico_11")}</td>
                                                                    <td>{t("centro_historico_12")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("centro_historico_13")}</td>
                                                                    <td>{t("centro_historico_14")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("centro_historico_15")}</td>
                                                                    <td>{t("centro_historico_16")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("centro_historico_17")}</td>
                                                                    <td>{t("centro_historico_18")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("centro_historico_19")}</td>
                                                                    <td>{t("centro_historico_20")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("centro_historico_21")}</td>
                                                                    <td>{t("centro_historico_22")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("centro_historico_23")}</td>
                                                                    <td>
                                                                        <ul className="table-list-allow">
                                                                            <li><i className="bx bx-check" />{t("centro_historico_24")}</li>
                                                                            <li><i className="bx bx-check" />{t("centro_historico_25")}</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("centro_historico_26")}</td>
                                                                    <td>
                                                                        <ul className="table-list-allow">
                                                                            <li><i className="bx bx-check" /> {t("centro_historico_27")}</li>
                                                                            <li> <i className="bx bx-check" /> {t("centro_historico_28")}</li>
                                                                            <li><i className="bx bx-check" /> {t("centro_historico_29")}</li>
                                                                            <li><i className="bx bx-check" /> {t("centro_historico_30")}</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("centro_historico_31")}</td>
                                                                    <td>
                                                                        <ul className="table-list-disallow">
                                                                            <li> <i className="bx bx-x" /> {t("centro_historico_32")}</li>
                                                                            <li><i className="bx bx-x" /> {t("centro_historico_33")}</li>
                                                                            <li><i className="bx bx-x" /> {t("centro_historico_34")}</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("centro_historico_35")}</td>
                                                                    <td>
                                                                        <ul className="table-list-allow">
                                                                            <li> <i className="bx bx-check" /> 
                                                                            {t("centro_historico_36")}</li>
                                                                            <li><i className="bx bx-check" /> {t("centro_historico_37")}</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("centro_historico_38")}</td>
                                                                    <td>
                                                                        <ul className="table-list-allow">
                                                                            <li> <i className="bx bx-check" /> 
                                                                            {t("centro_historico_39")}</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div>
                                                            <p>
                                                            {t("centro_historico_40")}
                                                            </p>
                                                            <p>
                                                            {t("centro_historico_41")}
                                                            </p>
                                                            <p>
                                                            {t("centro_historico_42")}

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                            <div className="tab-contant-3">
                                                <SRLWrapper>
                                                <div className="row">
                                                    <div className="col-lg-8 col-md-8">
                                                        <div className="package-grid-one">
                                                            <div className="single-grid">
                                                                <Link to={pd_thumb_4} className="g-img-sm-1 main-gallary">
                                                                    <img src={pd_thumb_4} alt="gallary-img" />
                                                                </Link>

                                                                <Link to={pd_thumb_3} className="g-img-sm-2 main-gallary">
                                                                    <img src={pd_thumb_3} alt="gallary-img" />
                                                                </Link>

                                                                <Link to={pd_thumb_6} className="g-img-md main-gallary">
                                                                    <img src={pd_thumb_6} alt="gallary-img" />
                                                                </Link>
                                                            </div>
                                                            <div className="single-grid mt-24">
                                                                <Link to={pd_thumb_7} className="g-img-sm-1 main-gallary">
                                                                    <img src={pd_thumb_7} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={pd_thumb_8} className="g-img-sm-2 main-gallary">
                                                                    <img src={pd_thumb_8} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={pd_thumb_10} className="g-img-md main-gallary">
                                                                    <img src={pd_thumb_10} alt="gallary-img" />
                                                                </Link>
                                                            </div>

                                                            <div className="single-grid mt-24">

                                                                <Link to={pd_thumb_11} className="g-img-sm-1 main-gallary">
                                                                    <img src={pd_thumb_11} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={pd_thumb_12} className="g-img-sm-2 main-gallary">
                                                                    <img src={pd_thumb_12} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={pd_thumb_14} className="g-img-md main-gallary">
                                                                    <img src={pd_thumb_14} alt="gallary-img" />
                                                                </Link>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4">
                                                        <div className="package-grid-two">
                                                            <div className="single-grid-2">
                                                                <Link to={pd_thumb_5}  className="main-gallary">
                                                                    <img src={pd_thumb_5} alt="gallary-img" />
                                                                </Link>
                                                            </div>
                                                            <div className="single-grid-2 mt-24">
                                                                <Link to={pd_thumb_9}  className="single-grid-2 main-gallary mt-30">
                                                                    <img src={pd_thumb_9} alt="gallary-img" />
                                                                </Link>
                                                            </div>
                                                            <div className="single-grid-2 mt-24">
                                                                <Link to={pd_thumb_15}  className="main-gallary mt-30">
                                                                    <img src={pd_thumb_15} alt="gallary-img" />
                                                                </Link>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                </SRLWrapper>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="package-d-sidebar">
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="p-sidebar-form">
                                            <form>
                                                <h5 className="package-d-head">{t("book_this_package")}</h5>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <input type="submit" name={t("book_now")} value={t("book_now")} />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-6">
                                        <div className="p-sidebar-cards mt-40">
                                            <h5 className="package-d-head">{t("popular_packages")}</h5>
                                            <ul className="package-cards">
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={pm_sm_4} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/lima_bahia`}>Lima Bay</Link>
                                                        </h3>
                                                        <h5><span>$95</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={pm_sm_1} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/lima_bohemian`}>Lima Bohemian</Link>
                                                        </h3>
                                                        <h5><span>$95</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={pm_sm_2} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/pachacamac`}>Pachacamac</Link>
                                                        </h3>
                                                        <h5><span>$187</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={pm_sm_5} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/central_andes`}>{t("central_andes_1")}</Link>
                                                        </h3>
                                                        <h5><span>$575</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={central_andes_2_1} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/central_andes_2`}>{t("central_andes_2_1")}</Link>
                                                        </h3>
                                                        <h5><span>$575</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={andes_a_la_selva_1} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/andes_a_la_selva`}>{t("andes_a_la_selva_1")}</Link>
                                                        </h3>
                                                        <h5><span>$780</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={andes_a_la_costa_1} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/andes_a_la_costa`}>{t("andes_a_la_costa_1")}</Link>
                                                        </h3>
                                                        <h5><span>$780</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
  }
}

// export default CentroHistorico;
export default withHooks(CentroHistorico);
