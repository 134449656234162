import React, { Component } from "react";
import {Link} from "react-router-dom";

import pd_thumb_2_1 from "../../../assets/images/perubike_images/centrol_andes_3.JPG"

import pm_sm_1  from "../../../assets/images/banners/banner_lima_bohemian.JPG";
import pm_sm_4  from "../../../assets/images/banners/banner_lima_bahia.jpg";
import pm_sm_2  from "../../../assets/images/banners/banner_pachacamac.JPG";


// Banners de packages
import centro_historico_1  from "../../../assets/images/banners/banner_centro_historico.JPG";
import andes_a_la_costa_1 from "../../../assets/images/perubike_images/de_los_andes_a_la_costa/banner_1.JPG"
import central_andes_1_1 from "../../../assets/images/perubike_images/centro_andes_1.JPG"
import central_andes_2_1 from "../../../assets/images/perubike_images/central_andes_2/banner_1.JPG";

import andes_a_la_selva_1 from "../../../assets/images/perubike_images/de los andes a la selva/andes_a_la_selva_1.JPG"
import andes_a_la_selva_2 from "../../../assets/images/perubike_images/de los andes a la selva/andes_a_la_selva_2.JPG"
import andes_a_la_selva_3 from "../../../assets/images/perubike_images/de los andes a la selva/andes_a_la_selva_3.JPG"
import andes_a_la_selva_4 from "../../../assets/images/perubike_images/de los andes a la selva/andes_a_la_selva_4.JPG"
import andes_a_la_selva_5 from "../../../assets/images/perubike_images/de los andes a la selva/andes_a_la_selva_5.JPG"
import andes_a_la_selva_6 from "../../../assets/images/perubike_images/de los andes a la selva/andes_a_la_selva_6.JPG"
import andes_a_la_selva_7 from "../../../assets/images/perubike_images/de los andes a la selva/andes_a_la_selva_7.JPG"
import andes_a_la_selva_8 from "../../../assets/images/perubike_images/de los andes a la selva/andes_a_la_selva_8.JPG"
import andes_a_la_selva_9 from "../../../assets/images/perubike_images/de los andes a la selva/banner_1.JPG"

import { SRLWrapper } from "simple-react-lightbox";

import "react-datepicker/dist/react-datepicker.css";

import "../../../i18n";
import { useTranslation } from "react-i18next";


function withHooks(WrappedComponent) {
    return function(props) {
      const {t} = useTranslation();
      return (
        < WrappedComponent t={t} {...props} />
      );
    };
  }

class AndesALaSelva extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate:new Date(),
        };
    }
    changeDatepickerHandeller=(date)=>{
        this.setState({ startDate: date });
    }

    componentDidMount(){
        this.scrollTop();
    }

    scrollTop()
    {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
  render() {
    const startDate =this.state.startDate;

    const {t} = this.props;

    return (
        <>
            {/* ===============  breadcrumb area start =============== */}
            <div className="breadcrumb-area-andes-a-la-selva  ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="breadcrumb-wrap">
                                <h2>{t("centro_historico_1")}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ===============  breadcrumb area end =============== */}
            <div className="package-details-wrapper pt-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="package-details">
                                <div className="package-thumb">
                                    <img src={andes_a_la_selva_1} alt="" />
                                </div>
                                <div className="package-header">
                                    <div className="package-title">
                                        <h3>{t("andes_a_la_selva_1")} {t("andes_a_la_selva_2")}</h3>
                                        <h4>{t("centro_historico_3")}</h4>
                                    </div>
                                </div>
                                <div className="package-tab">
                                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true"><i className="flaticon-info" />
                                                {t("information")}</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false"> <i className="flaticon-gallery" />
                                                {t("our_gallary")}</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content p-tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="tab-content-1">
                                                        <div className="p-overview">
                                                            <h5>{t("centro_historico_3")}</h5>
                                                            <p>{t("andes_a_la_selva_3")}</p>
                                                        </div>
                                                        <br/>
                                                        <div className="p-overview">
                                                            <h5 style={{"margin":"0px"}}>{t("andes_a_la_selva_13")}</h5>
                                                            <p>{t("andes_a_la_selva_14")}</p> 
                                                            <p>{t("andes_a_la_selva_15")}</p>
                                                        </div>
                                                        <br/>
                                                        <div className="p-overview">
                                                            <h5 style={{"margin":"0px"}}>{t("andes_a_la_selva_16")}</h5>
                                                            <p>{t("andes_a_la_selva_17")}</p> 
                                                            <p>{t("andes_a_la_selva_18")}</p>
                                                            <p>{t("andes_a_la_selva_19")}</p>
                                                        </div>
                                                        <br/>
                                                        <div className="p-overview">
                                                            <h5 style={{"margin":"0px"}}>{t("andes_a_la_selva_20")}</h5>
                                                            <p>{t("andes_a_la_selva_21")}</p> 
                                                            <p>{t("andes_a_la_selva_22")}</p>
                                                            <p>{t("andes_a_la_selva_23")}</p>
                                                            <p>{t("andes_a_la_selva_24")}</p>
                                                            <p>{t("andes_a_la_selva_25")}</p>
                                                        </div>
                                                        <br/>
                                                        <div className="p-overview">
                                                            <h5 style={{"margin":"0px"}}>{t("andes_a_la_selva_26")}</h5>
                                                            <p>{t("andes_a_la_selva_27")}</p>
                                                        </div>
                                                        <br/>
                                                        <div className="package-thumb">
                                                            <img src={andes_a_la_selva_9} alt="" />
                                                        </div>
                                                        <br/>
                                                        <div className="p-details-table">
                                                            <table className="table caption-top">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>{t("andes_a_la_selva_30")} {t("andes_a_la_selva_31")}</td>
                                                                        <td>{t("andes_a_la_selva_32")}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{t("andes_a_la_selva_33")}</td>
                                                                        <td>{t("andes_a_la_selva_33_1")}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{t("andes_a_la_selva_34")}</td>
                                                                        <td>{t("andes_a_la_selva_34_1")}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{t("andes_a_la_selva_35")}</td>
                                                                        <td>{t("andes_a_la_selva_35_1")}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{t("andes_a_la_selva_37")}</td>
                                                                        <td>
                                                                            <ul className="table-list-allow">
                                                                                <li><i className="bx bx-check" />{t("andes_a_la_selva_38")}</li>
                                                                                <li><i className="bx bx-check" />{t("andes_a_la_selva_39")}</li>
                                                                                <li><i className="bx bx-check" />{t("andes_a_la_selva_40")}</li>
                                                                                <li><i className="bx bx-check" />{t("andes_a_la_selva_41")}</li>
                                                                                <li><i className="bx bx-check" />{t("andes_a_la_selva_42")}</li>
                                                                                <li><i className="bx bx-check" />{t("andes_a_la_selva_43")}</li>
                                                                                <li><i className="bx bx-check" />{t("andes_a_la_selva_44")}</li>
                                                                                <li><i className="bx bx-check" />{t("andes_a_la_selva_45")}</li>
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{t("andes_a_la_selva_46")}</td>
                                                                        <td>
                                                                            <ul className="table-list-allow">
                                                                                <li><i className="bx bx-x" /> {t("andes_a_la_selva_47")}</li>
                                                                                <li> <i className="bx bx-x" /> {t("andes_a_la_selva_48")}</li>
                                                                                <li><i className="bx bx-x" /> {t("andes_a_la_selva_49")}</li>
                                                                                <li><i className="bx bx-x" /> {t("andes_a_la_selva_50")}</li>
                                                                                <li><i className="bx bx-x" /> {t("andes_a_la_selva_51")}</li>
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                            <div className="tab-contant-3">
                                                <SRLWrapper>
                                                <div className="row">
                                                    <div className="col-lg-8 col-md-8">
                                                        <div className="package-grid-one">
                                                        <div className="single-grid">
                                                                <Link to={andes_a_la_selva_1} className="g-img-sm-1-2 main-gallary">
                                                                    <img src={andes_a_la_selva_1} alt="gallary-img" />
                                                                </Link>

                                                                <Link to={andes_a_la_selva_2} className="g-img-sm-2-2 main-gallary">
                                                                    <img src={andes_a_la_selva_2} alt="gallary-img" />
                                                                </Link>

                                                                <Link to={andes_a_la_selva_3} className="g-img-md-2 main-gallary">
                                                                    <img src={andes_a_la_selva_3} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={andes_a_la_selva_9} className="g-img-md-2 main-gallary">
                                                                    <img src={andes_a_la_selva_9} alt="gallary-img" />
                                                                </Link>
                                                            </div>
                                                            <div className="single-grid mt-24">
                                                                <Link to={andes_a_la_selva_4} className="g-img-sm-1-2 main-gallary">
                                                                    <img src={andes_a_la_selva_4} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={andes_a_la_selva_5} className="g-img-sm-2-2 main-gallary">
                                                                    <img src={andes_a_la_selva_5} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={andes_a_la_selva_6} className="g-img-md-2 main-gallary">
                                                                    <img src={andes_a_la_selva_6} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={andes_a_la_selva_9} className="g-img-md-2 main-gallary">
                                                                    <img src={andes_a_la_selva_9} alt="gallary-img" />
                                                                </Link>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4">
                                                        <div className="package-grid-two">
                                                            <div className="single-grid-3">
                                                                <Link to={andes_a_la_selva_7}  className="main-gallary">
                                                                    <img src={andes_a_la_selva_7} alt="gallary-img" />
                                                                </Link>
                                                            </div>
                                                            <div className="single-grid-3 mt-24">
                                                                <Link to={andes_a_la_selva_8}  className="single-grid-2 main-gallary mt-30">
                                                                    <img src={andes_a_la_selva_8} alt="gallary-img" />
                                                                </Link>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                </SRLWrapper>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="package-d-sidebar">
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="p-sidebar-form">
                                            <form>
                                                <h5 className="package-d-head">{t("book_this_package")}</h5>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <input type="submit" name={t("book_now")} value={t("book_now")} />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-6">
                                        <div className="p-sidebar-cards mt-40">
                                            <h5 className="package-d-head">{t("popular_packages")}</h5>
                                            <ul className="package-cards">
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={pm_sm_4} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/lima_bahia`}>{t("lima_bohemian_45")}</Link>
                                                        </h3>
                                                        <h5><span>$95</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={pm_sm_1} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/lima_bohemian`}>{t("pachacamac_56")}</Link>
                                                        </h3>
                                                        <h5><span>$95</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={pm_sm_2} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/pachacamac`}>Pachacamac</Link>
                                                        </h3>
                                                        <h5><span>$187</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={centro_historico_1} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/centro_historico`}>{t("lima_bohemian_51")}</Link>
                                                        </h3>
                                                        <h5><span>$95</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={central_andes_1_1} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/central_andes`}>{t("central_andes_1")}</Link>
                                                        </h3>
                                                        <h5><span>$575</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={central_andes_2_1} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/central_andes_2`}>{t("central_andes_2_1")}</Link>
                                                        </h3>
                                                        <h5><span>$575</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={andes_a_la_costa_1} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/andes_a_la_costa`}>{t("andes_a_la_costa_1")}</Link>
                                                        </h3>
                                                        <h5><span>$780</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
  }
}

// export default CentroHistorico;
export default withHooks(AndesALaSelva);
